
import {Container, Row, Col } from "reactstrap";
import { Typography } from "@mui/material";
import {useSelector } from "react-redux";
import HeaderCard from "./HeaderCard";
import './header.css'


const Header = ({tables}) => {
  const { readyToAmmendUsersCount, ammendedUsersCount, unAmmendedUsersCount } = useSelector(state => state.dashaboardCardsData);
  const { totalAwaitingCalculationUsers, lockedUsersCount, unLockedUsersCount } = useSelector(state => state.dashaboardCardsData);

  console.log("readyToAmmendUsersCount:", readyToAmmendUsersCount);
  console.log("ammendedUsersCount:", ammendedUsersCount);
  console.log("unAmmendedUsersCount:", unAmmendedUsersCount);
  console.log("totalAwaitingCalculationUsers:", totalAwaitingCalculationUsers);
  console.log("lockedUsersCount:", lockedUsersCount);
  console.log("unLockedUsersCount:", unLockedUsersCount);

//   let readyToAmmendUsersCount= 0
//   let ammendedUsersCount = 0
//   let unAmmendedUsersCount = 0
//  let  totalAwaitingCalculationUsers = 0
//   let lockedUsersCount = 0 
//   let unLockedUsersCount = 0


  return (
    <>
    <div className="header pb-5 pt-5 pt-md-8" style={{ background: 'linear-gradient(87deg,#11cdef,#1171ef)' }}>
      {!tables && (
        <Container fluid>
          {/* <div className="header-body">
            <Typography variant="subtitle1" sx={{ color: '#ffffff', marginBottom: '3px' }}>Awaiting Calculations</Typography>
            <Row>
              <Col lg="6" xl="4">
                <HeaderCard cardTitle="Assigned Leads" cardValue={totalAwaitingCalculationUsers} icon="fas fa-user-check" color="#FF5733" />
              </Col>
              <Col lg="6" xl="4">
                <HeaderCard cardTitle="Submitted Leads" cardValue={lockedUsersCount} icon="fas fa-file-alt" color="#FFC300" />
              </Col>
              <Col lg="6" xl="4">
                <HeaderCard cardTitle="Pending Leads" cardValue={unLockedUsersCount} icon="fas fa-hourglass-half" color="#C70039" />
              </Col>
            </Row>
            <Typography variant="subtitle1" sx={{ color: '#ffffff', marginBottom: '3px', marginTop: '20px' }}>Ready to Amend</Typography>
            <Row>
              <Col lg="6" xl="4">
                <HeaderCard cardTitle="Ready To Amend Leads" cardValue={readyToAmmendUsersCount} icon="fas fa-edit" color="#900C3F" />
              </Col>
              <Col lg="6" xl="4">
                <HeaderCard cardTitle="Cleared Leads" cardValue={ammendedUsersCount} icon="fas fa-check-circle" color="#581845" />
              </Col>
              <Col lg="6" xl="4">
                <HeaderCard cardTitle="Pending Amend Leads" cardValue={unAmmendedUsersCount} icon="fas fa-hourglass-half" color="#FF5733" />
              </Col>
            </Row>
          </div> */}
        </Container>
      )}
    </div>
  </>
  
  
  
  );
};

export default Header;
