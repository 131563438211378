import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  readyToAmmendUsersCount: 0,
  ammendedUsersCount : 0,
  unAmmendedUsersCount : 0,
  totalAwaitingCalculationUsers : 0,
  lockedUsersCount : 0 , 
  unLockedUsersCount : 0
}

export const dashaboardCardsDataSlice = createSlice({
  name: 'dashaboardCardsData',
  initialState,
  reducers: {
      updateReadyToAmmendUsersCount: (state , action) => {
      state.readyToAmmendUsersCount = action.payload.count
      },
      updateAmmendedUsersCount: (state , action) => {
        state.ammendedUsersCount = action.payload.count
      },
      updateUnAmmendedUsersCount: (state , action) => {
        state.unAmmendedUsersCount = action.payload.count
      },
      updateAwaitingCalculationCount: (state , action) => {
        state.totalAwaitingCalculationUsers = action.payload.count
      },
      updateLockedUsersCount: (state , action) => {
        state.lockedUsersCount = action.payload.count
      },
      updateUnLockedUsersCount: (state , action) => {
        state.unLockedUsersCount = action.payload.count
      },
       
  },
})

// Action creators are generated for each case reducer function
export const { updateReadyToAmmendUsersCount, updateAmmendedUsersCount, updateUnAmmendedUsersCount , updateAwaitingCalculationCount , updateLockedUsersCount ,updateUnLockedUsersCount }
 = dashaboardCardsDataSlice.actions

export default dashaboardCardsDataSlice.reducer