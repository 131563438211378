import {
  Card,
  CardHeader,
  Container,
  Row,
  UncontrolledTooltip,
  Dropdown,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { useEffect, useState } from "react";
import moment from "moment";
import axios from "../../axios/axios.js";
import "./style.css";
import Swal from 'sweetalert2'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";
import { baseUrl } from "baseUrl";
import { useNavigate } from "react-router-dom";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useDispatch, useSelector } from "react-redux";


const Tables = () => {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedLimit, setSelectedLimit] = useState(10); // State for selected limit
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [count , setCount] = useState(0)
  const [emailSentUsersCount  , setEmailSentUsersCount] = useState(0)
  const [shouldReload , setShouldReload] = useState(false)
  const { readyToAmmendUsersCount } = useSelector(
    (state) => state.dashaboardCardsData
  );
  useEffect(() => {
    localStorage.removeItem("activeTab");
  }, []);

  let verifiedLeadsCount = 0;

  const handleRowClick = (user) => {
    try {
      navigate(`/userPanel/` , {state : user});
    } catch (err) {}
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    if (searchText.length > 0) {
      searchUsers();
    }
  }, [searchText]);

  useEffect(() => {
    if (searchText == "") {
      fetchUsers();
    }
  }, [searchText , shouldReload]);

  useEffect(() => {
    fetchUsers();
  }, [page, rowsPerPage, verifiedLeadsCount, dispatch , shouldReload]);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const response = await axios.post(`user/getAllLeads`, {
        page: page + 1, // API expects 1-based index
        limit: rowsPerPage,
      });
      setUsers(response.data.result);
      setCount(response?.data?.count)
      setEmailSentUsersCount(response?.data?.email_sent_count)
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching users:", error);
    }
  };


  const searchUsers = async () => {
    try {
      setLoading(true);
      const response = await axios.post(`user/searchGetAllLeads`, {
        page: 1, // API expects 1-based index
        limit: rowsPerPage,
        searchText: searchText,
      });
      setUsers(response.data.result);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching users:", error);
      setLoading(false);
    }
  };


  const handleSendEmailClick = () => {
    Swal.fire({
      title: "Do you intend to email each and every user?",
      html: `Total of <strong>${emailSentUsersCount}</strong> users are pending for email!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, send!",
      showLoaderOnConfirm: true,

      preConfirm: async (login) => {
        Swal.update({
            title: 'Sending Email...',
            allowOutsideClick: false,
            showConfirmButton: false,
            html : "We are sending an email, so please wait. Do not reload the page.",
            iconHtml: `<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" class="bi bi-send-arrow-up" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M15.854.146a.5.5 0 0 1 .11.54l-2.8 7a.5.5 0 1 1-.928-.372l1.895-4.738-7.494 7.494 1.376 2.162a.5.5 0 1 1-.844.537l-1.531-2.407L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM5.93 9.363l7.494-7.494L1.591 6.602z"/>
            <path fill-rule="evenodd" d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.354-5.354a.5.5 0 0 0-.722.016l-1.149 1.25a.5.5 0 1 0 .737.676l.28-.305V14a.5.5 0 0 0 1 0v-1.793l.396.397a.5.5 0 0 0 .708-.708z"/>
          </svg>`,
          icon : "info"
          });
        
           let response = await axios.post("sendEmail/sendEmailToLeadsReminder")
      },
      allowOutsideClick: () => !Swal.isLoading()

    }).then((result) => {
      if (result.isConfirmed) {
        setShouldReload(!shouldReload)
        Swal.fire({
          title: "Email Sent!",
          text: "Email has been sent to all users.",
          icon: "success",
        });
      }
   
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0); // Reset to the first page when changing rows per page
    setSelectedLimit(newRowsPerPage); // Update the selected limit state
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#5e72e4",
      color: theme.palette.common.white,
      zIndex: "0",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const tableHeaders = [
    "Lead",
    "Email Sent Status" ,
  ];
  function getRemainingDays(targetTime, totalDays) {
    const currentTime = moment();
    const targetDate = moment(targetTime, "YYYY-MM-DD HH:mm:ss");
    const daysPassed = currentTime.diff(targetDate, "days");
    const remainingDays = totalDays - daysPassed;
    return remainingDays;
  }

  const getDocumentSubmissionStatus = (user) => {
    console.log(user?.stripe_time);
    if (
      user?.pre_signature_document &&
      JSON.parse(user?.pre_signature_document) &&
      JSON.parse(user?.pre_signature_document).length > 0 &&
      user?.pre_signature_second_document &&
      JSON.parse(user?.pre_signature_second_document) &&
      JSON.parse(user?.pre_signature_second_document).length > 0
    ) {
      return "Cleared";
    } else if (user?.stripe_time) {
      const targetTime = user?.stripe_time;
      const totalDays = 5;
      const remainingDays = getRemainingDays(targetTime, totalDays);
      console.log(remainingDays);
      if (remainingDays <= 0) {
        return "Time Expired";
      }
      return `${remainingDays} Days Left`;
    } else return "Time Expired";
  };
  return (
    <>
      <Header tables={"tables"} />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-3">ERC</h3>
                <div
                  className="gap-2"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <div
                    className="mr-3"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <label
                      style={{
                        marginTop: "5px",
                        fontSize: "15px",
                        display: "inline-block",
                        marginBottom: "0.5rem",
                      }}
                    >
                      Show:
                    </label>
                    <select
                      className="form-control form-control-sm"
                      style={{ marginLeft: 6, fontSize: 16 }}
                      value={selectedLimit} // Reflect selected limit
                      onChange={handleChangeRowsPerPage} // Handle change event
                    >
                      <option value={5}>5 Records</option>
                      <option value={10}>10 Records</option>
                      <option value={20}>20 Records</option>
                      <option value={50}>50 Records</option>
                      <option value={100}>100 Records</option>
                    </select>
                  </div>
                  <div className="d-flex">
                  <div className="mr-3">
                    <div className="input-group input-group-lg flex-nowrap">
                      <div className="input-group-prepend">
                        <span
                          className="input-group-text"
                          style={{
                            backgroundColor: "transparent",
                            borderRight: "none",
                          }}
                        >
                          <i
                            className="fas fa-search"
                            style={{ color: "#5e72e4" }}
                          ></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        placeholder="Search by name..."
                        className="form-control form-control-sm"
                        // Adjust width as needed
                        value={searchText}
                        onChange={(e) => handleSearchChange(e)}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                  <button
                    onClick={emailSentUsersCount && emailSentUsersCount > 0 ? handleSendEmailClick : undefined} disabled={!emailSentUsersCount || emailSentUsersCount <= 0}
                    className="btn btn-success d-flex justify-content-center align-items-center"
                    style={{ gap: 7 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-envelope-at-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2 2A2 2 0 0 0 .05 3.555L8 8.414l7.95-4.859A2 2 0 0 0 14 2zm-2 9.8V4.698l5.803 3.546zm6.761-2.97-6.57 4.026A2 2 0 0 0 2 14h6.256A4.5 4.5 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586zM16 9.671V4.697l-5.803 3.546.338.208A4.5 4.5 0 0 1 12.5 8c1.414 0 2.675.652 3.5 1.671" />
                      <path d="M15.834 12.244c0 1.168-.577 2.025-1.587 2.025-.503 0-1.002-.228-1.12-.648h-.043c-.118.416-.543.643-1.015.643-.77 0-1.259-.542-1.259-1.434v-.529c0-.844.481-1.4 1.26-1.4.585 0 .87.333.953.63h.03v-.568h.905v2.19c0 .272.18.42.411.42.315 0 .639-.415.639-1.39v-.118c0-1.277-.95-2.326-2.484-2.326h-.04c-1.582 0-2.64 1.067-2.64 2.724v.157c0 1.867 1.237 2.654 2.57 2.654h.045c.507 0 .935-.07 1.18-.18v.731c-.219.1-.643.175-1.237.175h-.044C10.438 16 9 14.82 9 12.646v-.214C9 10.36 10.421 9 12.485 9h.035c2.12 0 3.314 1.43 3.314 3.034zm-4.04.21v.227c0 .586.227.8.581.8.31 0 .564-.17.564-.743v-.367c0-.516-.275-.708-.572-.708-.346 0-.573.245-.573.791" />
                    </svg>
                    Send Email
                  </button>
                </div>
                  </div>
                 
                </div>
           
               
              </CardHeader>
              <Paper>
                <TableContainer>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow className="table_head">
                        {tableHeaders.map((item, index) => (
                          <StyledTableCell
                            key={index}
                            style={{
                              fontSize: "0.8rem",
                              fontWeight: "bold",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {item}
                          </StyledTableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    {users?.length == 0 && !loading && (
                      <StyledTableRow>
                        <TableCell colSpan={6} align="center">
                          No record found
                        </TableCell>
                      </StyledTableRow>
                    )}

                    {loading && users?.length == 0 ? (
                      <>
                        {Array.from({ length: 5 }, (_, index) => (
                          <StyledTableRow key={index}>
                            <TableCell>
                              <div
                                style={{ padding: "7px", background: "#f6f7f" }}
                              >
                                <Skeleton height={8} width={150} />
                                <Skeleton height={8} width={200} />
                              </div>
                            </TableCell>
                            <TableCell>
                              <div
                                style={{ padding: "7px", background: "#f6f7f" }}
                              >
                                <Skeleton height={8} width={150} />
                              </div>
                            </TableCell>
                      
                          </StyledTableRow>
                        ))}
                      </>
                    ) : (
                      <TableBody className="table_body">
                        {users?.map((user) => (
                          <StyledTableRow
                            key={user.id}
                            // onClick={() => handleRowClick(user)}
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <h6 className="mb-0 text-xs">
                                  {(user?.name ? user?.name : "")}
                          
                                </h6>

                                <span className="text-xs text-secondary mb-0">
                                  {user?.email}
                                </span>
                             
                              </div>
                            </TableCell>
                            <TableCell className={user?.email_sent_status == "pending"? "text-xs text-capitalize text-danger mb-0" : "text-xs text-capitalize text-success mb-0"}  style={{fontWeight : 'bold'}} >
                              {user?.email_sent_status || 'Pending'}
                            </TableCell>
                        
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 20, 50, 100]}
                  component="div"
                  count={count} // Assuming you know the total count of users
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Tables;
