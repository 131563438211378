import Sidebar from "components/Sidebar/Sidebar";
import React, {useState } from "react";
import routes from "routes.js";
import "./style.css";
import { StatusTab } from "./components/StatusTab";
import {
  Card,
  CardHeader,
  Container,
  Row,

} from "reactstrap";

import Header from "components/Headers/Header";
import AdminNavbar from "components/Navbars/AdminNavbar";
import "components/GlobalPanelStyles/panel.css";
import { useLocation } from "react-router-dom";


// core components

const UserPanel = (props) => {
  const { state : userData} = useLocation();
  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props?.location?.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  const [activeTab, setActiveTab] = useState("status_tab"); // Default to 'status_tab' or last selected tab

    return (
      <>
        <Sidebar
          routes={routes}
          logo={{
            innerLink: "/admin/index",
            imgSrc: require("../assets/img/brand/argon-react.png"),
            imgAlt: "...",
          }}
        />
        <div className="main-content">
          <AdminNavbar
            {...props}
            brandText={getBrandText(props?.location?.pathname)}
          />
          {/* Header */}
          <Header tables={"tables"} />

          {/* Page content */}
          <Container className="mt--7 " fluid>
            {/* Table */}
            <Row>
              <div className="col" style={{ padding: "0px 0px 50px 0px" }}>
                <Card className="" style={{ backgroundColor: "#4969a0" }}>
                  <CardHeader className="bg-transparent border-0">
                    <h3 className="text-white mb-0">Lead Panel Information</h3>
                  </CardHeader>
                  <div className="status-page">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-xl-7 col-lg-12 col-sm-12 col-md-12">
                          <div className="contain">
                            <div className="row ">
                              <div className="main_tab_content col-lg-9 col-md-12">
                                <div className="row justify-content-center">
                                  <div className="col-lg-12 col-md-12 ">
                                    <ul
                                      className="nav nav-tab tabs-heading mb-4"
                                      role="tab"
                                      style={{
                                        alignItems: "center !important",
                                      }}
                                    >
                                      <li className="tab-item me-4">
                                        <a
                                          className={`status-heading nav-link ${
                                            activeTab === "status_tab"
                                              ? "active"
                                              : ""
                                          }`}
                                          data-bs-toggle="tab"
                                          href="#status_tab"
                                          style={{
                                            marginTop: 5,
                                            fontSize: 18,
                                            textDecoration: "underline",
                                          }}
                                        >
                                          User information
                                        </a>
                                      </li>
                                    
                                    </ul>

                                    <div className="tab-content mt-2">
                                      <div
                                        className={`tab-pane fade ${
                                          activeTab === "status_tab"
                                            ? "show active"
                                            : ""
                                        }`}
                                        id="status_tab"
                                      >
                                        <StatusTab
                                          userData={userData && userData}
                                        />
                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            </Row>
          </Container>
        </div>
      </>
    );
};

export default UserPanel;
