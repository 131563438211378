import React from "react";
import moment from 'moment'

export const StatusTab = ({ userData }) => {
  console.log(userData)
  const fields = {
    "Name": `${userData?.name ||'-'}`,
    "Email": userData?.email ? userData.email : '-',
    "Phone": userData?.phone ? userData.phone : '-',
    "Company Name": userData?.company_name || '-',
    "Address": userData?.address_line_1 ? userData.address_line_1 : '-',
    "City": userData?.city ? userData?.city : '-',
    "Payment Type" : userData?.payment_type || '-',
    "Date Fee Paid" : userData?.date_fee_paid || "-",
    "Credit Amount" : userData?.credit_amount ?  `$${userData?.credit_amount}`: '-',
    "Date Filed" : userData?.date_filed || '-',
    "Total Commisstion" : userData?.commission_amount || '-',
  };
return (
  <>
    <h2 className="mb-3 comp-info">Company Info</h2>

    <div className="row justify-content-start">
      {Object.keys(fields).map((key, index) => (
        <div key={index} className="col-lg-6">
          <div className="mb-3">
            <div
              className="form-label-status styleTitle"
              style={{
                marginBottom: '0.25rem',
                lineHeight: '1.25',
                color: 'rgba(20,18,22,.6)'
            
              }}
            >
              {key}
            </div>
            <div style={{ color: key === 'Veriff Status' && fields[key] == 'approved' ? 'green' : null  , fontWeight : key === 'Verification Status' ? '600' : null }} className="status-inform">{fields[key]}</div>          
            </div>
        
        </div>
      ))}
    </div>
  </>
);
};