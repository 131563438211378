
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import App from './App'
import { AuthenticationProvider } from './AuthenticationProvider'
import {store} from './Redux/Store/store'
import { Provider } from 'react-redux';
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.min.js";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
  <Provider store = {store}>
  <AuthenticationProvider>
      <App />
    </AuthenticationProvider>
  </Provider>
  </BrowserRouter>
);
